<template>
  <section>
    <loading-spinner v-if="loading" />
    <h5 class="mb-5 text-center mt-5" />
    <no-results-found v-if="noResultsFound === true" />
    <b-row
      cols="1"
      cols-sm="1"
      cols-md="2"
      cols-lg="3"
    >
      <b-col
        v-for="(data) in followers"
        :key="data.id"
        :class="['mt-3 px-2', style['followers-col']]"
      >
        <followers-cards
          :data="data"
          :handleButtonActions="handleButtonActions"
        />
      </b-col>
      <b-col
        v-for="(data) in following"
        :key="data.id"
        :class="['mt-3 px-2', style['followers-col']]"
      >
        <following-cards
          :data="data"
          :handleButtonActions="handleButtonActions"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import style from "../../styles/followers-following.module.scss";
import {API_IMAGE_URL } from "../../config/config";
import NoResultsFound from '../Followers/NoResultsFound.vue';
import FollowersCards from '../Followers/FollowersCards.vue';
import FollowingCards from "../Followers/FollowingCards.vue";
import { search, nextPage, removeGab, followFab } from '../../api/followersSearch';
import { getAdsDetail } from '../../api/feed';
import LoadingSpinner from '../Common/LoadingSpinner';
export default {
    name: "FollowersList",
    components: {FollowingCards, NoResultsFound, FollowersCards, LoadingSpinner },
    data() {
        return {
            API_IMAGE_URL,
            style,
            selectedCeleb: "",
            celebList: [],
            subCategory: [],
            subCat:[],
            subId: "",
            catId: "",
            followers: [],
            following: [],
            popularCeleb: "",
            selectCeleb: {},
            noResultsFound: false,
            followFlag: false,
            next_page_load: true,
            next_page_url: '',
            loading:false,
            tabType: "",
            objToSendRequest: {},
            username: this.$route.params ? this.$route.params.username : null,
            account_type: localStorage.getItem("account-type"),
        };
    },
    watch:{
        followers:function(newVal){
            this.followers = newVal;
        },
        following:function(newVal){
            this.following = newVal;
        }
    },
    async created() {
        this.loading = true;
        getAdsDetail();
        window.addEventListener("scroll", this.scroll);
        this.followers = [];
        let objToSend = {
            type: 'followers',
            is_pagination: 1,
            page:1
        };
        if (this.username === "" || this.username === null || this.username === undefined){
            if (this.account_type === "1") {
                objToSend = {
                    type: 'followings',
                    is_pagination: 1,
                    page:1
                };
            }
        }
        if (this.username !== null && this.username !== undefined) {
            objToSend.username = this.username ? this.username : null;
        }
        this.handleSearch(objToSend);
    },
    mounted(){
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scroll);
    },
    methods: {
        async scroll() {
            var body = document.body,
                html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight );
            var middle_height = height / 2;
            if (window.scrollY > middle_height) {
                this.scrolledToBottom = true;
                this.pageRefresh = true;
                this.counter = this.counter + 1;
                if (this.next_page_load === true){
                    this.next_page_load = false;
                    let list = await nextPage(this.objToSendRequest, this.next_page_url);
                    if (list !== undefined && list !== null){
                        list.data.map(value => {
                            if (this.objToSendRequest && this.objToSendRequest.type === 'followers') {
                                this.followers.push(value);
                            } else {
                                this.following.push(value);
                            }
                            this.next_page_url = list.next_page_url;
                            if (this.next_page_url !== null) {
                                this.objToSendRequest.page =  list.current_page + 1;
                            }
                            this.next_page_load = true;
                        });
                    }
                }
            }
        },
        redirectToFeed(data){
            this.$router.push(`/feed/${data.username}`);
        },
        async handleSearch(objToSend) {
            this.loading = true;
            this.objToSendRequest = objToSend;
            let list = await search(objToSend);
            if (list !== undefined){
                if (list.data.length > 0){
                    this.loading = false;
                    this.noResultsFound = false;
                    this.next_page_url = list.next_page_url;
                    if (this.next_page_url !== null) {
                        this.objToSendRequest.page =  list.current_page + 1;
                    }
                    this.next_page_load = true;
                    if (objToSend && objToSend.type === "followers"){
                        this.followers = list.data;
                        this.following = [];
                        this.tabType = objToSend.type;
                    } else {
                        this.followers = [];
                        this.following = list.data;
                        this.tabType = objToSend.type;
                    }
                } else {
                    this.loading = false;
                    this.next_page_url = list.next_page_url;
                    if (this.next_page_url !== null) {
                        this.objToSendRequest.page =  list.current_page + 1;
                    }
                    this.next_page_load = true;
                    this.noResultsFound = true;
                    if (objToSend && objToSend.type === "followers"){
                        this.followers = list.data;
                        this.following = [];
                        this.tabType = objToSend.type;
                    } else {
                        this.followers = [];
                        this.following = list.data;
                        this.tabType = objToSend.type;
                    }
                }
            }
        },
        handleButtonActions(data,buttonType,requestFromCard) {
            if (buttonType === 'remove') {
                this.handleRemove(data);
            } else if (buttonType === 'unfollow') {
                this.handleFollow(data, 0, 'unfollow', requestFromCard);
            } else {
                this.handleFollow(data, 1, 'follow', requestFromCard);
            }
        },
        async handleRemove(data) {
            this.followFlag = true;
            let objToSend = {
                gab_id: data.id,
                status: 0,
            };
            let follow = await removeGab(objToSend);
            let followersArr = [];
            if (Object.keys(follow).length > 0){
                let me = this;
                if (this.followFlag){
                    this.followFlag = false;
                    me.followers.forEach((val) => {
                        if (val.id !== data.id) {
                            followersArr.push(val);
                        }
                    });
                    this.followers = followersArr;
                    if (this.followers.length <= 0) {
                        this.noResultsFound = true;
                    }
                }
            }
        },
        async handleFollow(data, status, actionType, requestFromCard) {
            this.followFlag = true;
            let objToSend = {
                fab_id: data.id,
                status: status,
            };
            let follow = await followFab(objToSend);
            let followersArr = [];
            let followingArr = [];
            if (Object.keys(follow).length > 0){
                let me = this;
                if (this.followFlag){
                    this.followFlag = false;
                    if (requestFromCard === 'followerscard') {
                        me.followers.forEach((val) => {
                            if (val.id === data.id){
                                val.is_follow = status;
                            }
                            followersArr.push(val);
                            /*if (val.id !== data.id) {
                                followersArr.push(val);
                            }*/
                        });
                        this.followers = followersArr;
                        if (actionType === 'follow') {
                            if (data.account_type == 2) {
                                this.redirectToFeed(data);
                            }
                        }
                        if (this.followers.length <= 0) {
                            this.noResultsFound = true;
                        }
                    } else {
                        me.following.forEach((val) => {
                            if (actionType === 'follow') {
                                if (val.id === data.id){
                                    val.is_follow = status;
                                }
                                followingArr.push(val);
                            } else {
                                if (val.id !== data.id) {
                                    followingArr.push(val);
                                }
                            }
                        });
                        this.following = followingArr;
                        if (actionType === 'follow') {
                            if (data.account_type == 2) {
                                this.redirectToFeed(data);
                            }
                        }
                        if (this.following.length <= 0) {
                            this.noResultsFound = true;
                        }
                    }
                }
            }
        }
    },
};
</script>
