<template>
  <div :class="[style['celeb-box'], 'text-center']">
    <img
      :id="`profile${data.id}`"
      :src="data.profile_pic_url"
      :class="[
        data.account_type === 2 ? style['searched-username'] : '',
        'mt-3',
      ]"
      @error="handleError(`profile${data.id}`)"
      @click="handleRedirection(data)"
    >
    <div class="text-center ml-3 mr-3">
      <p
        :class="[
          data.account_type === 2 ? style['searched-username'] : '',
          'font-weight-bold font-open-sans mb-0',
        ]"
        @click="handleRedirection(data)"
      >
        {{ data.name + " " + data.surname }}
      </p>
      <p
        v-if="data.account_type === 2"
        class="font-weight-light font-open-sans mb-0 text-truncate"
      >
        {{ catName !== undefined ? catName : "" }} -
        <span
          v-for="(value, index) in subCategory"
          :key="index"
        >{{ value }}{{ subCategory.length > 1 ? "," : "" }}</span>
      </p>
      <p
        v-else
        :class="[
          style['no-category'],
          'font-weight-light font-open-sans text-truncate',
        ]"
      />
      <p
        v-if="data.about_you !== null"
        class="font-weight-light font-open-sans mb-3 text-truncate"
      >
        {{ data.about_you !== "null" ? data.about_you : "" }}
      </p>
      <p
        v-else
        :class="[
          'font-weight-light font-open-sans text-truncate mb-0',
          style['no-description'],
        ]"
      />
    </div>
    <div
      v-if="userId != data.id"
      class="text-center d-flex justify-content-center"
    >
      <b-link
        v-if="
          (data.is_follow === 0 &&
            data.account_type === 1 &&
            accountType == 2 &&
            data.is_follow_me === 1) ||
            (data.is_follow_me === 1 && data.account_type === 2)
        "
        class="btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3 mr-2"
        @click="handleButtonActions(data, 'remove', 'followerscard')"
      >
        Remove
      </b-link>
      <b-link
        v-if="data.is_follow === 0 && data.account_type === 2"
        class="btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3 ml-2"
        @click="handleButtonActions(data, 'follow', 'followerscard')"
      >
        Follow
      </b-link>
    </div>
  </div>
</template>

<script>
import style from "../../styles/followers-following.module.scss";
import placeholderImge from "../../assets/download.jpeg";
export default {
    name: "FollowersCards",
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        handleButtonActions: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            style,
            subCategory: [],
            category: "",
            catName: "",
            username: this.$route.params.username
                ? this.$route.params.username
                : null,
            userId: localStorage.getItem("userId"),
            accountType: localStorage.getItem("account-type"),
        };
    },
    created() {
        if (
            this.data.categories !== undefined &&
      this.data.categories.length > 0 &&
      this.data.categories[0] !== undefined && this.data.categories[0].cateogry
        ) {
            this.catName = this.data.categories[0].cateogry.name;
            for (var i = 0; i < this.data.categories.length; i++) {
                for (var j = 0; j < this.data.categories.length; j++) {
                    if (
                        this.data.categories[i].cateogry.name ===
            this.data.categories[j].cateogry.name
                    ) {
                        this.subCategory.push(
                            this.data.categories[i].sub_cateogry.sub_cat_name
                        );
                        this.subCategory = [...new Set(this.subCategory)];
                    }
                }
            }
        }
    },
    methods: {
        handleRedirection(data) {
            if (data.username !== null && data && data.account_type == 2) {
                let username = data.username;
                this.$router.push(`/feed/${username}`);
            }
        },
        handleError(data) {
            document.getElementById(data).src = placeholderImge;
        },
    },
};
</script>
