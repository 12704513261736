<template>
  <section :class="[style['search-list-wrap'], 'w-100']">
    <loading-spinner v-if="loading" />
    <b-container :class="['py-md-4', style['celebsearch-container']]">
      <div class="my-md-5 my-4 p-3">
        <b-row class="justify-content-center mx-0">
          <b-col
            xl="12"
            lg="13"
          >
            <b-row class="justify-content-center">
              <b-col md="9">
                <b-tabs
                  content-class="mt-5"
                  :class="style['follower-following-tabs']"
                  align="center"
                >
                  <b-tab
                    v-if="is_hide_followersTab"
                    title="Followers"
                    active
                    :disabled="is_disabled_followersTab"
                    @click="handleTabs('followers')"
                  />
                  <b-tab
                    title="Following"
                    @click="handleTabs('following')"
                  />
                </b-tabs>
              </b-col>
            </b-row>
            <b-row class="mx-0">
              <b-col md="12">
                <b-input-group>
                  <template #append>
                    <b-input-group-text
                      :class="[
                        style['closeIcon'],
                        'bg-transparent',
                        'h-100',
                        'border-0',
                      ]"
                    >
                      <img
                        v-if="searchValue !== ''"
                        src="../assets/close.png"
                        alt=""
                        width="18"
                        @click="handleReset(objToSendRequest.type)"
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="search"
                    placeholder="Start typing..."
                    size="lg"
                    :class="[style['gab-search-bar'], 'border-0', 'pt-3']"
                    name="search-bar"
                    @input="handleSearch"
                  />
                </b-input-group>
                <FollowersList
                  v-if="searching === false"
                  ref="searchEvent"
                />
                <b-row
                  v-if="searching === true && noResultsFound === false"
                  class="justify-content-center mx-0"
                >
                  <span
                    :class="[style['search-text'], 'mt-5']"
                  >{{ totalSearch }} search results</span>
                </b-row>
                <SearchedResults
                  v-if="searching === true && noResultsFound === false"
                  :searchedResults="searchedResults"
                  :followingSearchedResults="followingSearchedResults"
                  @handledetailsforfollowers="handledetailsForFollowers"
                  @handledetailsforfollowing="handledetailsForFollowing"
                />
                <NoResultsFound
                  v-if="noResultsFound"
                  :searchedValue="searchValue"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-row class="mx-0" />
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import style from "../styles/followers-following.module.scss";
import SearchedResults from "../components/Followers/FollowersSearchResults.vue";
import FollowersList from "../components/Followers/FollowersList.vue";
import NoResultsFound from "../components/Followers/NoResultsFound.vue";
import {removeGab, followFab } from '../api/followersSearch';
/*import Advertisement from '../components/Advertisements/Advertisement.vue';*/
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../js/api_url";
import { advertisementsList } from '../api/feed';
import LoadingSpinner from '../components/Common/LoadingSpinner';
export default {
    name: "FollowersFollowing",
    components: {
        SearchedResults,
        FollowersList,
        NoResultsFound,
        /*Advertisement,*/
        LoadingSpinner
    },
    data() {
        return {
            style,
            searching: false,
            next_page_load: false,
            noResultsFound: false,
            searchedResults: [],
            followingSearchedResults: [],
            totalSearch: 0,
            searchValue: "",
            advertisements:[],
            loading: false,
            id: null,
            username: this.$route.params.username ? this.$route.params.username : null,
            objToSendRequest: {},
            is_disabled_followersTab : false,
            is_hide_followersTab : true,
            is_disabled_followingTab: false,
            account_type: localStorage.getItem("account-type"),
            selectedTab: 'followers'
        };
    },
    watch: {
        username(newVal){
            this.getObjectToSend(newVal); 
            this.handleTabs(this.selectedTab);
        }
    },
    async created(){
        this.$watch(
            () => this.$route.params,
            (toParams) => {
                this.username = toParams.username ? toParams.username : null;
            }
        );
        let objToSend = {
            type: 'followers',
            is_pagination: 1,
            page:1
        };
        if (this.username === "" || this.username === null || this.username === undefined){
            if (this.account_type === "1") {
                objToSend = {
                    type: 'followings',
                    is_pagination: 1,
                    page:1
                };
            }
        }
        if (this.username !== null && this.username !== undefined){
            this.is_disabled_followersTab = false;
            this.is_hide_followersTab = true;
            this.is_disabled_followingTab = false;
        } else {
            if (this.account_type === "1") {
                this.is_disabled_followersTab = true;
                this.is_hide_followersTab = false;
                this.is_disabled_followingTab = false;
            }
        }
        if (this.username !== null && this.username !== undefined) {
            objToSend.username = this.username ? this.username : null;
        }
        this.objToSendRequest = objToSend;
        let advertisement = await advertisementsList();
        if (advertisement.length > 0){
            this.advertisements = advertisement;
        }
    },
    methods: {
        scroll() {
            var body = document.body,
                html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight );
            var middle_height = height / 2;
            if (window.scrollY > middle_height) {
                this.scrolledToBottom = true;
                this.pageRefresh = true;
                this.counter = this.counter + 1;
            }
        },
        redirectToFeed(data){
            this.$router.push(`/feed/${data.username}`);
        },
        getObjectToSend(username){
            let objToSend = {
                type: 'followers',
                is_pagination: 1,
                page:1
            };
            if (username === "" || username === null || username === undefined){
                if (this.account_type === "1") {
                    objToSend = {
                        type: 'followings',
                        is_pagination: 1,
                        page:1
                    };
                }
            }
            if (username !== null && username !== undefined){
                this.is_disabled_followersTab = false;
                this.is_hide_followersTab = true;
                this.is_disabled_followingTab = false;
            } else {
                if (this.account_type === "1") {
                    this.is_disabled_followersTab = true;
                    this.is_hide_followersTab = false;
                    this.is_disabled_followingTab = false;
                }
            }
            if (username !== null && username !== undefined) {
                objToSend.username = username ? username : null;
            }
            this.objToSendRequest = objToSend;
        },
        handleSearch(value) {
            this.searchedResults = [];
            this.followingSearchedResults = [];
            this.searchValue = value;
            if (value === "" || value.length < 3) {
                this.searching = false;
                this.noResultsFound = false;
                this.objToSendRequest.search = value;
                if (this.objToSendRequest.type === 'followers'){
                    this.selectedTab = 'followers';
                    this.$nextTick(() => {
                        this.$refs.searchEvent?.handleSearch(this.objToSendRequest);
                    });
                } else {
                    this.selectedTab = 'following';
                    this.$nextTick(() => {
                        this.$refs.searchEvent?.handleSearch(this.objToSendRequest);
                    });
                }
            } else {
                this.loading = true;
                this.searching = true;
                this.objToSendRequest.search = value;
                if (this.username !== null && this.username !== undefined) {
                    this.objToSendRequest.username = this.username ? this.username : null;
                }
                axios
                    .get(API_BASE_URL + APIUrlList.FOLLOWERSFOLLOWINGS,{
                        headers: {Authorization: "Bearer " + localStorage.getItem("user-token")},
                        params: this.objToSendRequest,
                    })
                    .then((response) => {
                        this.searchedResults = [];
                        this.followingSearchedResults = [];
                        if (response.data.status === false) {
                            this.noResultsFound = true;
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.searchedResults = [];
                            this.followingSearchedResults = [];
                            this.noResultsFound = false;
                            if (this.objToSendRequest && this.objToSendRequest.type == 'followers') {
                                this.searchedResults = response.data.data.data;
                                this.followingSearchedResults = [];
                            } else {
                                this.followingSearchedResults = response.data.data.data;
                                this.searchedResults = [];
                            }
                            this.totalSearch = response.data.data.data.length;
                        }
                    });
            }
        },
        handleTabs(value){
            let objToSend = {};
            objToSend.type = value;
            objToSend.is_pagination = 1;
            objToSend.page = 1;
            if (this.username !== null && this.username !== undefined) {
                objToSend.username = this.username ? this.username : null;
            }
            if (this.searchValue.length >= 0) {
                this.handleReset();
            }
            this.objToSendRequest = objToSend;
            if (value === 'followers'){
                this.selectedTab = 'followers';
                this.$nextTick(() => {
                    this.$refs.searchEvent?.handleSearch(this.objToSendRequest);
                });
            } else {
                this.selectedTab = 'following';
                this.$nextTick(() => {
                    this.$refs.searchEvent?.handleSearch(this.objToSendRequest);
                });
            }
        },
        async handledetailsForFollowers(newVal){
            let objToSend = {
                gab_id: newVal.data.id,
                status: 0,
            };
            let follow = await removeGab(objToSend);
            let followersArr = [];
            if (Object.keys(follow).length > 0){
                let me = this;
                me.searchedResults.forEach((val) => {
                    if (val.id !== newVal.id) {
                        followersArr.push(val);
                    }
                });
                this.searchedResults = followersArr;
                if (this.searchedResults.length <= 0) {
                    this.noResultsFound = true;
                }
            }
        },
        async handledetailsForFollowing(newVal){
            let objToSend = {
                fab_id: newVal.data.id,
                status: newVal.status,
            };
            let follow = await followFab(objToSend);
            let followersArr = [];
            let followingArr = [];
            if (Object.keys(follow).length > 0){
                let me = this;
                if (newVal.requestFromCard === 'followerscard') {
                    me.searchedResults.forEach((val) => {
                        if (val.id === newVal.data.id){
                            val.is_follow = status;
                        }
                        followersArr.push(val);
                        /*if (val.id !== newVal.data.id) {
                            followersArr.push(val);
                        }*/
                    });
                    this.searchedResults = followersArr;
                    if (newVal.actionType === 'follow') {
                        if (newVal.data.account_type == 2) {
                            this.redirectToFeed(newVal.data);
                        }
                    }
                    if (this.searchedResults.length <= 0) {
                        this.noResultsFound = true;
                    }
                } else {
                    me.followingSearchedResults.forEach((val) => {
                        if (newVal.actionType === 'follow') {
                            if (val.id === newVal.data.id){
                                val.is_follow = status;
                            }
                            followingArr.push(val);
                        } else {
                            if (val.id !== newVal.data.id) {
                                followingArr.push(val);
                            }
                        }
                    });
                    this.followingSearchedResults = followingArr;
                    if (newVal.actionType === 'follow') {
                        if (newVal.data.account_type == 2) {
                            this.redirectToFeed(newVal.data);
                        }
                    }
                    if (this.followingSearchedResults.length <= 0) {
                        this.noResultsFound = true;
                    }
                }
            }
        },
        handleReset(resetType = null){
            document.getElementById('search').value = "";
            this.searching = false;
            this.noResultsFound = false;
            if (resetType !== null) {
                this.handleTabs(resetType);
            }
        }
    }
};
</script>

<style scoped>

</style>
