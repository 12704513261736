<template>
  <section>
    <b-row
      cols="1"
      cols-sm="1"
      cols-md="2"
      cols-lg="3"
      class="mx-0"
    >
      <b-col
        v-for="(data) in followersSearchResult"
        :key="data.id"
        :class="['mt-3', style['followers-col']]"
      >
        <followers-cards
          :data="data"
          :handleButtonActions="handleButtonActions"
        />
      </b-col>
      <b-col
        v-for="(data) in followingSearchResult"
        :key="data.id"
        class="mt-3"
      >
        <following-cards
          :data="data"
          :handleButtonActions="handleButtonActions"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import style from "../../styles/followers-following.module.scss";
import FollowersCards from './FollowersCards.vue';
import FollowingCards from "./FollowingCards.vue";
export default {
    name: "FollowersSearchResults",
    components:{ FollowersCards, FollowingCards },
    props: {
        searchedResults:{
            type: Array,
            default:()=>[]
        },
        followingSearchedResults:{
            type: Array,
            default:()=>[]
        },
        searchedValue:{
            type:String,
            default: '',
        }
    },

    data() {
        return {
            style,
            followersSearchResult: [],
            followingSearchResult: [],
            followFlag:false
        };
    },
    watch: {
        searchedResults: function(newVal) {
            this.followersSearchResult = newVal;
        },
        followingSearchedResults: function(newVal) {
            this.followingSearchResult = newVal;
        },
    },
    created() {
        this.followersSearchResult = [];
        this.followersSearchResult = this.searchedResults;
        this.followingSearchResult = [];
        this.followingSearchResult = this.followingSearchedResults;
    },
    methods: {
        handleButtonActions(data,buttonType,requestFromCard) {
            if (buttonType === 'remove') {
                this.$emit('handledetailsforfollowers', data);
            } else if (buttonType === 'unfollow') {
                let requestSentData = {
                    data: data,
                    status: 0,
                    buttonType: buttonType,
                    requestFromCard: requestFromCard
                };
                this.$emit('handledetailsforfollowing', requestSentData);
            } else {
                let requestSentData = {
                    data: data,
                    status: 1,
                    buttonType: buttonType,
                    requestFromCard: requestFromCard
                };
                this.$emit('handledetailsforfollowing', requestSentData);
            }
        }
    },
};
</script>
