var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.style['celeb-box'], 'text-center']},[_c('img',{class:[
      _vm.data.account_type === 2 ? _vm.style['searched-username'] : '',
      'mt-3' ],attrs:{"id":("profile" + (_vm.data.id)),"src":_vm.data.profile_pic_url},on:{"error":function($event){return _vm.handleError(("profile" + (_vm.data.id)))},"click":function($event){return _vm.handleRedirection(_vm.data)}}}),_c('div',{staticClass:"text-center ml-3 mr-3"},[_c('p',{class:[
        _vm.data.account_type === 2 ? _vm.style['searched-username'] : '',
        'font-weight-bold font-open-sans mb-0' ],on:{"click":function($event){return _vm.handleRedirection(_vm.data)}}},[_vm._v(" "+_vm._s(_vm.data.name + " " + _vm.data.surname)+" ")]),(_vm.data.account_type === 2)?_c('p',{staticClass:"font-weight-light font-open-sans mb-0 text-truncate"},[_vm._v(" "+_vm._s(_vm.catName !== undefined ? _vm.catName : "")+" - "),_vm._l((_vm.subCategory),function(value,index){return _c('span',{key:index},[_vm._v(_vm._s(value)+_vm._s(_vm.subCategory.length > 1 ? "," : ""))])})],2):_c('p',{class:[
        _vm.style['no-category'],
        'font-weight-light font-open-sans text-truncate' ]}),(_vm.data.about_you !== null)?_c('p',{staticClass:"font-weight-light font-open-sans mb-3 text-truncate"},[_vm._v(" "+_vm._s(_vm.data.about_you !== "null" ? _vm.data.about_you : "")+" ")]):_c('p',{class:[
        'font-weight-light font-open-sans text-truncate mb-0',
        _vm.style['no-description'] ]})]),(_vm.userId != _vm.data.id)?_c('div',{staticClass:"text-center d-flex justify-content-center"},[(
        (_vm.data.is_follow === 0 &&
          _vm.data.account_type === 1 &&
          _vm.accountType == 2 &&
          _vm.data.is_follow_me === 1) ||
          (_vm.data.is_follow_me === 1 && _vm.data.account_type === 2)
      )?_c('b-link',{staticClass:"btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3 mr-2",on:{"click":function($event){return _vm.handleButtonActions(_vm.data, 'remove', 'followerscard')}}},[_vm._v(" Remove ")]):_vm._e(),(_vm.data.is_follow === 0 && _vm.data.account_type === 2)?_c('b-link',{staticClass:"btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3 ml-2",on:{"click":function($event){return _vm.handleButtonActions(_vm.data, 'follow', 'followerscard')}}},[_vm._v(" Follow ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }