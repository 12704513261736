import axios from 'axios';
import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';

/**
 * Search Fab using Search Bar
 */
export const search = async (data) => {
    let searchedResults = {};
    let username = data ? data.username : "";
    let url = APIUrlList.FOLLOWERSFOLLOWINGS+`?type=${data.type}&is_pagination=${data.is_pagination}&page_limit=${9}`;
    if (username !== "" && username !== undefined) {
        url = APIUrlList.FOLLOWERSFOLLOWINGS+`?type=${data.type}&username=${data.username}&is_pagination=${data.is_pagination}&page_limit=${9}`;
    }
    try {
        const response = await apiCall(url, null, 'GET', true);
        searchedResults = response.data.data;
        return searchedResults;
    } catch (error){
        _sendResponse("error", error);
    }
};

/**
 * search next page
 */

export const nextPage = async (data,url = null) => {
    try {
        if (url !== null){
            let username = data ? data.username : "";
            let requesturl = url+`&type=${data.type}&is_pagination=${1}&page_limit=${9}`;
            if (username !== "" && username !== undefined) {
                requesturl = url+`?type=${data.type}&username=${data.username}&is_pagination=${data.is_pagination}&page_limit=${9}`;
            }
            var response =  await axios.get(requesturl, {headers: {Authorization: 'Bearer '+localStorage.getItem('user-token')}});
            return response.data.data;
        }
        return null;
    } catch (error){
        _sendResponse('error', error);
    }
};

/**
 * Handle remove
 */

export const removeGab = async (data) => {
    try {
        const response = await apiCall(APIUrlList.REMOVEGAB, data, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }

};


/**
 * Handle follow and unfollow
 */

export const followFab = async (data) => {
    try {
        const response = await apiCall(APIUrlList.FOLLOWFAB, data, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }

};
